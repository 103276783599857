// src/models/Invader.js

class Invader {
    constructor(id, position, status, instagramUrl, captured) {
        this.id = id;
        this.position = position; // [latitude, longitude]
        this.status = status; // 'ok' ou 'not-ok'
        this.instagramUrl = instagramUrl;
        this.captured = captured;
    }

    // flashable
    isOk() {
        return this.status === 'OK' && !this.isCaptured();
    }

    isCaptured() {
        return this.captured;
    }

    updateStatus(newStatus) {
        this.status = newStatus;
    }

    getDescription() {
        return `Invader ${this.id}: ${this.instagramUrl} (Statut : ${this.status})`;
    }
}

export default Invader;
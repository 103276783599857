import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import CustomMarker from '../../components/CustomMarker';
import FilterControl from '../../components/FilterControl';
import Invader from '../../models/Invader';
import "leaflet/dist/leaflet.css";
import PageLayout from '../layout/PageLayout'
import {useAuth0} from "@auth0/auth0-react";
import { InvaderService } from "../../services/InvaderService"

const CenterMapOnUser = ({ position }: { position: [number, number] }) => {
    const map = useMap();
    useEffect(() => {
        if (position) {
            map.setView(position, 13); // Centre la carte sur la position de l'utilisateur avec un zoom de 13
        }
    }, [map, position]);

    return null;
};

const MapPage = () => {
    const [invaders, setInvaders] = useState<Invader[]>([]);
    const [filterStatus, setFilterStatus] = useState('all'); // 'all' or 'not-ok'
    const [initialInvaders, setInitialInvaders] = useState<Invader[]>([]);
    const [userPosition, setUserPosition] = useState<[number, number] | null>(null);
    const { getAccessTokenSilently } = useAuth0();

    // Fonction de filtre
    const filterMarkers = () => {
        if (filterStatus === 'all') {
            setInvaders(initialInvaders); // Réinitialiser au complet
            setFilterStatus('ok');
        } else {
            setInvaders(invaders.filter((invader: Invader) => invader.isOk()));
            setFilterStatus('all');
        }
    };

    useEffect(() => {
        const handleNewInvader = (newInvader: Invader) => {
            console.log("New invader: id "+newInvader.id);
            setInvaders((prevInvaders) => [...prevInvaders, newInvader]);
            setInitialInvaders((prevInvaders) => [...prevInvaders, newInvader]);
        };

        InvaderService.fetchStream(getAccessTokenSilently, handleNewInvader).catch((error) =>
            console.error("Erreur lors de la récupération du flux des invaders :", error)
        );

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserPosition([position.coords.latitude, position.coords.longitude]);
                },
                (error) => {
                    console.error('Erreur de géolocalisation :', error);
                    // Définissez une position par défaut si la géolocalisation échoue
                    setUserPosition([48.8566, 2.3522]); // Paris, France
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000, // 5 secondes
                    maximumAge: 0
                }
            );
        } else {
            console.error('La géolocalisation n’est pas prise en charge par ce navigateur.');
            setUserPosition([48.8566, 2.3522]); // Paris, France
        }
    }, [getAccessTokenSilently]);

    return (
        <PageLayout>
            <MapContainer center={userPosition || [48.8566, 2.3522]} zoom={13} style={{ height: '100dvh'}}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <FilterControl onFilter={filterMarkers} filterStatus={filterStatus} />
                {invaders.map((invader: Invader, index) => (
                    <CustomMarker
                        key={index}
                        invader={invader}
                    />
                ))}
                {userPosition && <CenterMapOnUser position={userPosition} />}
            </MapContainer>
        </PageLayout>
    );
};

export default MapPage;
import React from "react";

type Parameters = {
    icon: string,
    onClickFunction: (event: React.MouseEvent<HTMLElement>) => void // function to call
}

const MobileMenuToggleButton = ({ icon, onClickFunction }: Parameters) => {
    return (
        <span
            className="mobile-nav-bar__toggle material-icons"
            id="mobile-menu-toggle-button"
            onClick={onClickFunction}
        >
      {icon}
    </span>
    );
};

export default MobileMenuToggleButton;
import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useAuth0 } from "@auth0/auth0-react";
import { InvaderService } from "../services/InvaderService"

const CustomMarker = ({ invader }) => {
    const color = invader.isCaptured() ? '#008000' : (invader.isOk() ? '#3333ff' : '#ff6833');
    console.log(invader);
    const { getAccessTokenSilently } = useAuth0();

    const handleMarkAsTook = async (id) => {
        try {
            await InvaderService.markAsTook(getAccessTokenSilently, id);
            console.log("Marked as took");
        } catch (error) {
            console.error("Erreur lors de la requête:", error);
        }
    };

    // Convertir l'icône Material-UI en HTML statique
    const iconMarkup = renderToStaticMarkup(
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 24 24" width="40px" height="40px">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 4.25 6 11 6 11s6-6.75 6-11c0-3.87-3.13-7-6-7z"/>
        </svg>
    );

    // Créer une icône Leaflet personnalisée à partir du HTML généré
    const customIcon = L.divIcon({
        html: iconMarkup,
        className: 'custom-div-icon', // Utilisez cette classe pour des styles supplémentaires
        iconSize: [40, 40],
        iconAnchor: [20, 40], // Point d'ancrage
        popupAnchor: [0, -40], // Position du popup
    });

    return (
        <Marker position={invader.position} icon={customIcon}>
            <Popup>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ margin: 0, marginRight: '10px' }}>{invader.id}</h3>
                    {!invader.isCaptured() && (
                        <CameraAltIcon
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                fontSize: '30px',
                                transition: 'transform 0.2s',
                            }}
                            onClick={() => handleMarkAsTook(invader.id)}
                            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
                            onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        />
                    )}
                </div>
            </Popup>
        </Marker>
    );
};

export default CustomMarker;
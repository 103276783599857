import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import NavBarTab from "./NavBarTab";

const NavBarTabs = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <div className="nav-bar__tabs">
            {isAuthenticated && (
                <>
                    <NavBarTab path="/map" label="Carte" />
                    <NavBarTab path="/leaderboard" label="Classement" />
                    <NavBarTab path="/profile" label="Profile" />
                </>
            )}
        </div>
    );
};

export default NavBarTabs;

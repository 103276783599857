import React from "react";
import NavigationBar from "../../components/navigation/desktop/NavigationBar";
import MobileNavigationBar from "../../components/navigation/mobile/MobileNavigationBar";

const CallbackPage = () => {
    return (
        <div className="page-layout">
            <NavigationBar />
            <MobileNavigationBar />
            <div className="page-layout__content" />
        </div>
    );
};

export default CallbackPage;
import {useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PageLayout from '../layout/PageLayout';
import CodeSnippet from '../../components/snippet/CodeSnippet'
import { GamerService } from "../../services/GamerService"


const ProfilePage = () => {
    const { user } = useAuth0();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        GamerService.saveGamer(getAccessTokenSilently, user?.sub, user?.given_name, user?.family_name).catch((error) =>
            console.error("Erreur lors de la sauvegarde de l'utilisateur :", error)
        );
    }, [getAccessTokenSilently, user]);

    if (!user) {
        return null;
    }

    return (
        <PageLayout>
            <div className="content-layout">
                <h1 id="page-title" className="content__title">
                    Profile Page
                </h1>
                <div className="content__body">
                    <p id="page-description">
                    </p>
                    <div className="profile-grid">
                        <div className="profile__header">
                            <img
                                src={user.picture}
                                alt="Profile"
                                className="profile__avatar"
                            />
                            <div className="profile__headline">
                                <h2 className="profile__title">{user.name}</h2>
                                <span className="profile__description">{user.email}</span>
                            </div>
                        </div>
                        <div className="profile__details">
                            <CodeSnippet
                                title="Decoded ID Token"
                                code={JSON.stringify(user, null, 2)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default ProfilePage;
import PageLayout from './layout/PageLayout'
import './HomePage.css';

const HomePage = () => {

    return (
        <PageLayout>
            <div className="home-container">
                <img src='./invader.svg' alt="Logo" style={{width: '150px', height: '100dvh'}}/>
            </div>
        </PageLayout>
    );
};

export default HomePage
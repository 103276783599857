import Invader from "../models/Invader";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export interface LeaderboardEntry {
  id: number;
  name: string;
  points: number;
}

export class InvaderService {
  static async markAsTook(
      getAccessTokenSilently: () => Promise<string>,
      invaderId: number
  ): Promise<void> {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${BASE_URL}/api/invaders/${invaderId}/mark-as-captured`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }

      console.log("Invader marked as took successfully");
    } catch (error) {
      console.error("Erreur lors de la requête :", error);
      throw error;
    }
  };

  static async fetchStream(
      getAccessTokenSilently: () => Promise<string>,
      onNewInvader: (invader: Invader) => void
  ): Promise<void> {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${BASE_URL}/api/invaders/stream`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-ndjson",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder("utf-8");

      if (reader) {
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.trim().split("\n");

          for (const line of lines) {
            try {
              const item = JSON.parse(line);
              const newInvader = new Invader(
                  item.id,
                  [item.lat, item.lng],
                  item.status,
                  item.instagramUrl,
                  item.captured
              );
              // Appeler le callback pour chaque invader reçu
              onNewInvader(newInvader);
            } catch (error) {
              console.error("Erreur lors du parsing JSON :", error);
            }
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      throw error;
    }
  };
  // TODO récupérer le type de l'access token
  static async fetchLeaderboard(accessToken : any): Promise<LeaderboardEntry[]> {
    try {
      const response = await fetch(`${BASE_URL}/api/invaders/leaderboard`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data.map((item: any) => ({
        id: item.id,
        name: item.name,
        points: item.points,
      }));
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      throw error;
    }
  }
}

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export class GamerService {
  static async saveGamer(
      getAccessTokenSilently: () => Promise<string>,
      userId: string | undefined,
      givenName : string | undefined,
      familyName : string|  undefined
  ): Promise<void> {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${BASE_URL}/api/gamer/${userId}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          id: userId,
          givenName: givenName,
          familyName: familyName,
        }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }

    } catch (error) {
      console.error("Erreur lors de la requête :", error);
      throw error;
    }
  };

}
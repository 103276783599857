import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthenticationGuard from "./security/auth/AuthenticationGuard";
import MapPage from "./pages/map/MapPage";
import ProfilePage from "./pages/profile/ProfilePage";
import LeaderboardPage from "./pages/leaderboard/LeaderboardPage";
import CallBackPage from "./pages/callback/CallBackPage";
import NotFoundPage from "./pages/notfound/NotFoundPage";
import PageLoader from "./pages/loader/PageLoader";
import HomePage from "./pages/HomePage";

function App() {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
  }
  return (
    <Routes>
     <Route path="/" element={<HomePage />} />
     <Route
        path="/map"
        element={<AuthenticationGuard component={MapPage} />}
     />
     <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
     />
     <Route
        path="leaderboard"
        element={<AuthenticationGuard component={LeaderboardPage} />}
     />
     <Route path="/callback" element={<CallBackPage />} />
     <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
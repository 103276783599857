import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import PageLoader from "../../pages/loader/PageLoader";

type Parameters = {
    component : any
}

const AuthenticationGuard = ({ component } : Parameters) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-layout">
                <PageLoader />
            </div>
        ),
    });

    return <Component />;
};

export default AuthenticationGuard;
import React from "react";
import NavigationBar from "../../components/navigation/desktop/NavigationBar";
import MobileNavigationBar from "../../components/navigation/mobile/MobileNavigationBar";
import PageFooter from "./PageFooter";

type Parameters = {
    children : any
}

const PageLayout = ({ children } : Parameters) => {
    return (
        <div className="page-layout">
            <NavigationBar />
            <MobileNavigationBar />
            <div className="page-layout__content">{children}</div>
            <PageFooter />
        </div>
    );
};

export default PageLayout;
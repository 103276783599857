import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
    Box,
    Grid2,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PageLayout from "../layout/PageLayout";
import { InvaderService } from "../../services/InvaderService"

interface LeaderboardEntry {
    id: number;
    name: string;
    points: number;
}

const LeaderboardPage = () => {
    const [leaderboardData, setLeaderBoardData] = useState<LeaderboardEntry[]>([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const loadLeaderboard = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const data = await InvaderService.fetchLeaderboard(accessToken);
                setLeaderBoardData(data);
            } catch (error) {
                console.error("Erreur lors du chargement du leaderboard :", error);
            }
        };
        loadLeaderboard();
    }, [getAccessTokenSilently]);

    if (leaderboardData.length === 0) {
        return <div>Chargement...</div>;
    }

    const topThree : LeaderboardEntry[] = leaderboardData.slice(0, 3);
    const others : LeaderboardEntry[] = leaderboardData.slice(3);

    return (
        <PageLayout>
            <Box sx={{ padding: 2 }}>
                {/* Section Podium */}
                <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        <EmojiEventsIcon fontSize="large" /> Classement <EmojiEventsIcon fontSize="large" />
                    </Typography>
                    <Grid2 container spacing={2} justifyContent="center" alignItems="flex-end">
                        {/* Deuxième place */}
                        <Grid2 size= {{xs:12, sm:4 }}>
                            <Paper
                                elevation={4}
                                sx={{
                                    padding: 2,
                                    backgroundColor: '#C0C0C0',
                                    height: '150px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    animation: 'fadeIn 1s ease-in-out',
                                }}
                            >
                                <Typography variant="h6">🥈 {topThree[1].name}</Typography>
                                <Typography variant="body1">{topThree[1].points} points</Typography>
                            </Paper>
                        </Grid2>
                        {/* Première place */}
                        <Grid2 size= {{xs:12, sm:4 }}>
                            <Paper
                                elevation={6}
                                sx={{
                                    padding: 3,
                                    backgroundColor: '#FFD700',
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transform: 'scale(1.1)',
                                    animation: 'fadeIn 1.5s ease-in-out',
                                }}
                            >
                                <Typography variant="h5">🥇 {topThree[0].name}</Typography>
                                <Typography variant="body1">{topThree[0].points} points</Typography>
                            </Paper>
                        </Grid2>
                        {/* Troisième place */}
                        <Grid2 size= {{xs:12, sm:4 }}>
                            <Paper
                                elevation={4}
                                sx={{
                                    padding: 2,
                                    backgroundColor: '#CD7F32',
                                    height: '130px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    animation: 'fadeIn 2s ease-in-out',
                                }}
                            >
                                <Typography variant="h6">🥉 {topThree[2].name}</Typography>
                                <Typography variant="body1">{topThree[2].points} points</Typography>
                            </Paper>
                        </Grid2>
                    </Grid2>
                </Box>

                {/* Section Tableau de classement */}
                <Box>
                    <Typography variant="h5" gutterBottom>
                        Classement complet
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Points</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {others.map((user, index) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{index + 4}</TableCell> {/* Débute à 4 car topThree occupe 1-3 */}
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.points}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </PageLayout>
    );
};

export default LeaderboardPage;
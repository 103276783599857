import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/styles.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithNavigate from "./security/auth/Auth0ProviderWithNavigate";

// src/index.js
async function enableMocking() {
    if (process.env.NODE_ENV !== 'development') {
        return
    }
    // const { worker } = await import('./mocks/browser')
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    // return worker.start()
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

enableMocking().then(() => {
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <App />
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </React.StrictMode>
    );
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { NavLink } from "react-router-dom";

const NavBarBrand = () => {
    return (
        <div className="nav-bar__brand">
            <NavLink to="/">
                <img
                    className="nav-bar__logo"
                    src="./invader.svg"
                    alt="SI tracker logo"
                    width="122"
                    height="36"
                />
            </NavLink>
        </div>
    );
};

export default NavBarBrand;

import React from "react";
import { NavLink } from "react-router-dom";

type Parameters = {
    onClickFunction: (event: React.MouseEvent<HTMLElement>) => void // function to call
};

const MobileNavBarBrand = ({ onClickFunction } : Parameters) => {
    return (
        <div onClick={onClickFunction} className="mobile-nav-bar__brand">
            <NavLink to="/">
                <img
                    className="nav-bar__logo"
                    src="./invader.svg"
                    alt="SI tracker logo"
                    width="82"
                    height="24"
                />
            </NavLink>
        </div>
    );
};

export default MobileNavBarBrand;

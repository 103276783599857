import {useMap} from "react-leaflet";
import L from 'leaflet';
import React from "react";

const FilterControl = ({ onFilter, filterStatus }) => {
    const map = useMap();

    // Ajouter un contrôle personnalisé sur la carte
    React.useEffect(() => {
        const filterButton = L.control({ position: 'topright' });

        filterButton.onAdd = () => {
            const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
            div.style.backgroundColor = 'white';
            div.style.padding = '5px';
            div.style.color = 'indigo';
            div.style.cursor = 'pointer';
            div.innerHTML = filterStatus === 'all' ? 'Tous' : 'A Flasher';
            div.onclick = () => onFilter();
            return div;
        };

        filterButton.addTo(map);

        return () => {
            map.removeControl(filterButton);
        };
    }, [map, filterStatus, onFilter]);

    return null; // Ce composant n'a pas de rendu React
};

export default FilterControl;
import React from "react";

const PageLoader = () => {
  const loadingImg = "./loader.svg";

  return (
    <div className="loader">
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
};

export default PageLoader;
import React from "react";

const PageFooter = () => {
    return (
        <footer className="page-footer">
        </footer>
    );
};

export default PageFooter;

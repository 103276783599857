import React from "react";
import { NavLink } from "react-router-dom";

type Parameters = {
    path : string,
    label: string,
    onClickFunction: (event: React.MouseEvent<HTMLElement>) => void // function to call
}

const MobileNavBarTab = ({ path, label, onClickFunction } : Parameters) => {
    return (
        <NavLink
            onClick={onClickFunction}
            to={path}
            end
            className={({ isActive }) =>
                "mobile-nav-bar__tab " + (isActive ? "mobile-nav-bar__tab--active" : "")
            }
        >
            {label}
        </NavLink>
    );
};

export default MobileNavBarTab;
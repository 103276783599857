import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import MobileNavBarTab from "./MobileNavBarTab";

type Parameters = {
    onClickFunction: (event: React.MouseEvent<HTMLElement>) => void // function to call
}

const MobileNavBarTabs = ({ onClickFunction } : Parameters) => {
    const { isAuthenticated } = useAuth0();

    return (
        <div className="mobile-nav-bar__tabs">
            {isAuthenticated && (
                <>
                    <MobileNavBarTab
                        path="/map"
                        label="Carte"
                        onClickFunction={onClickFunction}
                    />
                    <MobileNavBarTab
                        path="/leaderboard"
                        label="Classement"
                        onClickFunction={onClickFunction}
                    />
                    <MobileNavBarTab
                        path="/profile"
                        label="Profile"
                        onClickFunction={onClickFunction}
                    />
                </>
            )}
        </div>
    );
};

export default MobileNavBarTabs;
